import React from 'react'
import {Link} from 'gatsby'

import logo from '../img/groningen-stukadoor-logo.png'

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer has-background-black has-text-white-ter">

                <div className="content has-text-centered has-background-black has-text-white-ter">
                    <div className="container has-background-black has-text-white-ter">
                        <div className="columns">
                            <div className="column is-4">
                                <section>
                                    <ul className="menu-list">
                                        <div className="content has-text">
                                            <img
                                                src={logo}
                                                alt="Groningen Stukadoor Alfastuc"
                                                style={{width: '14em'}}
                                            />
                                        </div>
                                    </ul>
                                </section>
                            </div>
                            <div className="column is-4">
                                <section>
                                    <ul className="menu-list">
                                        <li>
                                            <Link to="/" className="navbar-item">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/alfastuc">
                                                Alfastuc
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/stucwerk">
                                                Stucwerk
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/sierpleister">
                                                Sierpleister
                                            </Link>
                                        </li>
                                        <li>
                                            <a
                                                className="navbar-item" to="/restauratie">
                                                Restauratie
                                            </a>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className="column is-4">
                                <section>
                                    <ul className="menu-list">
                                        <li>
                                            <Link className="navbar-item" to="/spacken">
                                                Spacken
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/design">
                                                Design
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/verf">
                                                Verf
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/verbouwing">
                                                Verbouwing
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
