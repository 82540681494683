import React from 'react'
import {Link} from 'gatsby'
import logo from '../img/groningen-stukadoor-logo.png'

const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                        navBarActiveClass: 'is-active',
                    })
                    : this.setState({
                        navBarActiveClass: '',
                    })
            }
        )
    }

    render() {
        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img src={logo} alt="Groningen Stukadoor Logo" style={{width: '250px'}}/>
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className="navbar-start has-text-centered">
                            <Link className="navbar-item" to="/stucwerk">
                                Stucwerk
                            </Link>
                            <Link className="navbar-item" to="/sierpleister">
                                Sierpleister
                            </Link>
                            <Link className="navbar-item" to="/restauratie">
                                Restauratie
                            </Link>
                            <Link className="navbar-item" to="/spacken">
                                Spacken
                            </Link>
                            <Link className="navbar-item" to="/design">
                                Design
                            </Link>
                            <Link className="navbar-item" to="/verf">
                                Verf
                            </Link>
                            <Link className="navbar-item" to="/verbouwing">
                                Verbouwing
                            </Link>
                            <Link className="navbar-item" to="/contact">
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
